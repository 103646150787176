<template>
    <div class="players-container">
        <ul>
            <li class="player-element" v-for="player in this.playersRoomStore.players" :key="player.id">
                <RouterLink v-if="player.id != null" v-bind:to='"/user/"+player.id'>
                    <img @click="showUserInfo" class="message-user-pfp" width="40px" height="40px" v-bind:src="'http://mafia.dottap.com/mafia/profile_photo/'+player.id+'.jpg'">
                </RouterLink>
                <span class="player-name" :class="player.sex == '1' ? 'player-girl' : 'player-boy'">{{ player.name }}</span>
            </li>
        </ul>   
    </div>
</template>

<script>
    import { usePlayersRoomStore } from '@/stores/PlayersRoomStore';
    import { useRoomStore } from '@/stores/RoomStore';
    export default {
        setup(){
            const playersRoomStore = usePlayersRoomStore()
            const roomStore = useRoomStore()
            return {playersRoomStore, roomStore}
        },
    }
</script>

<style>
.player-girl {
    background-color: #ff6cda;
}
.player-boy {
    background-color: rgb(62, 162, 255);
}
.players-container {
    min-width: 250px;
}
.player-name {
    padding: 3px 10px;
    border-radius: 7px;
    font-weight: bold;
    color: white;
}
.player-element {
    margin: 3px 0;
    display: flex;
    align-items: center;
}
</style>