<template v-if="message!=null" style="justify-content: center;">
    <div class="message-features" v-if="message.type===1 || message.type===9 || message.type=== 10 || message.type===11 || message.type == 17 || message.type===25">
        <a>
            <span class="message-features-button-show" v-if="featureTextDisplay==false">i</span>
            <span class="message-features-button-hide" v-if="featureTextDisplay==true" @click="hideFeature">h</span>
        </a>
        <div class="message-features-content">
            <button @click="featuresDecodeBase64">base64</button>
            <button @click="featuresDecodeMorse">morse</button>
        </div>
    </div>
    <RouterLink v-if="message.userid != null" v-bind:to='"/user/"+message.userid'>
        <img @click="showUserInfo" class="message-user-pfp" width="40px" height="40px" v-bind:src="'http://mafia.dottap.com/mafia/profile_photo/'+message.userid+'.jpg'">
    </RouterLink>
        <p class="message-entered" v-if="message.type==2"><span :class="message.sex == '1' ? 'message-username-girl' : 'message-username-boy'" @click="addText(displayText)" class="message-username-info">{{ displayText }}</span> entered</p>
    <p class="message-exited" v-else-if="message.type==3"><span :class="message.sex == '1' ? 'message-username-girl' : 'message-username-boy'" @click="addText(displayText)" class="message-username-info">{{ displayText }}</span> exited</p>

    <p class="message-entered" v-else-if="message.type==4">Game has started</p>
    <p class="message-entered" v-else-if="message.type==5">Night. Mafia's is talking</p>
    <p class="message-entered" v-else-if="message.type==6">Maifa's choosing a victim</p>
    <p class="message-entered" v-else-if="message.type==7">Day. Everyone's talking</p>
    <p class="message-entered" v-else-if="message.type==8">Day. Everyone's voting</p>
    <p class="message-entered" v-else-if="message.type==9"><span :class="message.sex == '1' ? 'message-username-girl' : 'message-username-boy'" @click="addText(message.username)" class="message-username">{{message.username}}</span> votes for {{displayText}}</p>
    <p class="message-last" v-else-if="message.type==11"><span :class="message.sex == '1' ? 'message-username-girl' : 'message-username-boy'" @click="addText(message.username)" class="message-username">{{message.username}}</span> {{displayText}}</p>

    <p class="message-entered" v-else-if="message.type==12">Player <span :class="message.sex == '1' ? 'message-username-girl' : 'message-username-boy'" @click="addText(displayText)" class="message-username-info">{{ displayText }}</span> was killed</p>
    
    <p class="message-entered" v-else-if="message.type==13"><span :class="message.sex == '1' ? 'message-username-girl' : 'message-username-boy'" @click="addText(message.username)" class="message-username">{{message.username}}</span> votes for {{displayText}}</p>

    <p class="message-entered" v-else-if="message.type==14">No one was killed</p>
    <p class="message-entered" v-else-if="message.type==15">Game has finished. Civilians won!</p>
    <p class="message-entered" v-else-if="message.type==16">Game has finished. Mafia won!</p>
    <p class="message-dead" v-else-if="message.type==17"><span :class="message.sex == '1' ? 'message-username-girl' : 'message-username-boy'" @click="addText(message.username)" class="message-username">{{message.username}}</span> {{displayText}}</p>
    <p class="message-entered" v-else-if="message.type==18">Terrorist blew up <span :class="message.sex == '1' ? 'message-username-girl' : 'message-username-boy'" @click="addText(displayText)" class="message-username-info">{{ displayText }}</span></p>
    <p class="message-entered" v-else-if="message.type==19">{{displayText}} - same team</p>
    <p class="message-entered" v-else-if="message.type==20">{{displayText}} - different team</p>
    
    <p class="message-entered" v-else-if="message.type==21">Terrorist tried to blow up <span :class="message.sex == '1' ? 'message-username-girl' : 'message-username-boy'" @click="addText(displayText)" class="message-username-info">{{ displayText }}</span> but the player was under bodyguard protection</p>

    <p class="message-entered" v-else-if="message.type==22">Game has finished with draw!</p>
    <p class="message-entered" v-else-if="message.type==25">Informator: {{ displayText }}</p>
    <p class="message" v-else><span :class="message.sex == '1' ? 'message-username-girl' : 'message-username-boy'" @click="addText(message.username)" class="message-username">{{message.username}}</span> <span class="message-text">{{ displayText }}</span> </p>
    
</template>

<script>
    import MorseCode from '@/morsecode'
    import { Base64 } from 'js-base64';
    export default {
        props: ['message'],
        data(){
            return {
                featureText: "",
                featureTextDisplay: false,
                displayText: this.message.text,
            }
        },
        methods: {
            addText(text){
                this.$parent.$emit('add-text-event', '[' + text + '] ');
            },
            featuresDecodeBase64(){
                try {
                    this.featureText = Base64.decode(this.message.text)
                    this.showFeature()
                } catch(e) {
                    // something failed

                    // if you want to be specific and only catch the error which means
                    // the base 64 was invalid, then check for 'e.code === 5'.
                    // (because 'DOMException.INVALID_CHARACTER_ERR === 5')
                }

            },
            featuresDecodeMorse() {
                this.featureText = MorseCode.decode(this.message.text)
                this.showFeature()
            },
            showFeature(){
                this.featureTextDisplay = true
                this.displayText = this.featureText
            },
            hideFeature(){
                this.featureTextDisplay = false
                this.displayText = this.message.text
            }
        },
        watch: {
            message: {
                handler (val, oldVal) {
                    this.displayText = this.message.text
                }
            }
        }
    }
</script>

<style>
.message-text {
    word-wrap: anywhere;
}
.message {
    background-image: linear-gradient(#e6dfff, rgb(159, 183, 255));
    padding: 5px 9px;
    border-radius: 6px;
    margin: 0;
}

.message-username {
    padding: 3px 10px;
    border-radius: 7px;
    font-weight: bold;
    color: #2c99c4;
    margin-right: 5px;
    cursor: pointer;
    text-shadow: #7fe2f9 0 0 10px;
    font-style: italic;
}
.message-user-pfp {
    object-fit: cover;
    border-radius: 50%;
    margin-right: 10px;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.message-username-info {
    background-color: rgb(230, 225, 253);
    color: rgb(255, 239, 255);
    border-radius: 7px;
    font-weight: bold;
    margin-right: 5px;
    padding: 3px 10px;
    cursor: pointer;
}
.message-entered {
    color:rgb(184, 107, 227);
    font-style: italic;
    font-weight: bold;
    margin: 0 auto;
}
.message-exited {
    color: rgb(62, 202, 217);
    font-style: italic;
    font-weight: bold;
    margin: 0 auto;
}
.message-dead {
    color: rgb(210, 118, 223);
}
.message-last {
    color: rgb(172, 172, 172);
}
.message-username-girl {
    color: #fb4399;
    text-shadow: #f2cedf 0 0 7px;
}
.message-username-boy {
    color: #2c99c4;
}
.message-features {
    position: relative;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.message-features-button-show {
    margin-right: 15px;
    color: rgb(83, 83, 83);
    font-weight: bold;
    font-style: italic;
    text-decoration: underline;
}
.message-features-button-hide {
    margin-right: 15px;
    color: rgb(32, 255, 99);
    font-weight: bold;
    font-style: italic;
    text-decoration: underline;
}
.message-features-content {
    display: none;
    position: absolute;
    z-index: 2;
}
.message-features-content button {
    padding: 2px;
    display: block;
}
.message-features:hover .message-features-content {
    display: block;
}
.message-time {
    margin-left: 10px;
}
</style>