<template>
    <div v-if="!createRoomToggle" class="roomlist">
        <div class="roomlist-filter">
            <button @click="createRoom" class="createroom-button">Create Room</button>
            <div class="roomlist-filter-el">
                <label for="free-rooms">Only free rooms</label>
                <input id="free-rooms" type="checkbox" v-model="onlyFreeRooms">
            </div>
            <p>Rooms with extra roles:</p>
            <div class="roomlist-filter-el">
                <label for="doctor">Doctor</label>
                <input id="doctor" type="checkbox" v-model="doctor">
            </div>
            <div class="roomlist-filter-el">
                <label for="lover">Lover</label>
                <input id="lover" type="checkbox" v-model="lover">
            </div>
            <div class="roomlist-filter-el">
                <label for="spy">Spy</label>
                <input id="spy" type="checkbox" v-model="spy">
            </div>
            <div class="roomlist-filter-el">
                <label for="journalist">Journalist</label>
                <input id="journalist" type="checkbox" v-model="journalist">
            </div>
            <div class="roomlist-filter-el">
                <label for="bodyguard">Bodyguard</label>
                <input id="bodyguard" type="checkbox" v-model="bodyguard">
            </div>
            <div class="roomlist-filter-el">
                <label for="terrorist">Terrorist</label>
                <input id="terrorist" type="checkbox" v-model="terrorist">
            </div>
            <div class="roomlist-filter-el">
                <label for="barman">Barman</label>
                <input id="barman" type="checkbox" v-model="barman">
            </div>
            <div class="roomlist-filter-el">
                <label for="informer">Informer</label>
                <input id="informer" type="checkbox" v-model="informer">
            </div>
        </div>
        <div class="roomlist-list">
            <ul class="room-list">
            <template v-for="room in filteredRooms" :key="room.id">
                <li class="room-list-el">
                    <RoomButton :room="room"></RoomButton>
                </li>
            </template>
            </ul>
        </div>
    </div>
    <CreateRoom v-if="createRoomToggle"></CreateRoom>
</template>

<script>
    import RoomButton from './RoomButton.vue'
    import CreateRoom from './CreateRoom.vue'

    export default {
        data(){
            return {
                createRoomToggle: false,
                onlyFreeRooms: false,
                
                doctor: false,
                lover: false,
                spy: false,
                journalist: false,
                bodyguard: false,
                terrorst: false,
                barman: false,
                informer: false,
            }
        },
        props: ['rooms'],
        components: {
            RoomButton,
            CreateRoom,
        },
        methods: {
            createRoom(){
                this.createRoomToggle = !this.createRoomToggle
            },
            applyFilter(room){
                if(this.onlyFreeRooms && room.status > 1){
                    return false;
                }
                if((this.doctor && !room.roles.includes(2))){
                    return false;
                }
                if(this.lover && !room.roles.includes(5)){
                    return false;
                }
                if(this.journalist && !room.roles.includes(7)){
                    return false;
                }
                if(this.informer && !room.roles.includes(11)){
                    return false;
                }
                if(this.spy && !room.roles.includes(10)){
                    return false;
                }
                if(this.barman && !room.roles.includes(9)){
                    return false;
                }
                if(this.bodyguard && !room.roles.includes(8)){
                    return false;
                }
                if(this.terrorst && !room.roles.includes(6)){
                    return false;
                }
                return true;
            }
        },
        computed: {
            filteredRooms() {
                return this.rooms.filter(room => this.applyFilter(room));
            },
        }
    }
</script>

<style>
.roomlist {
    display: flex;
    flex-direction: row;
}
.roomlist-filter {
    width: 250px;
    margin: 20px 0 0;

    position: sticky;
    top: 20px;
    display: flex;
    flex-direction: column;
    align-self: flex-start;

    background-color: #40c7e5bf;
    color: #fff;
    padding: 14px 20px;
    border-radius: 6px;
    box-shadow: 0 0 14px #fff;
}

.room-list-started{
    opacity: 30%;
    font-style: italic;
}
.room-list{
    color: aqua;
    display: flex;
    flex-wrap: wrap;
    max-width: 1400px;
    margin: 20px auto;
    padding: 0;
}
.room-list-el {
    flex: 1 1 100%;
    margin: 0 20px;
}
</style>